/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Button, Popover, Table, Tag, message } from 'antd';
import {
  CheckCircleTwoTone,
  InfoCircleTwoTone,
  CloseCircleTwoTone,
  DownloadOutlined,
  EnterOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import SongProps from '../../props/song';
import irUrl from '../../utils/irUrl';
import Vote from '../../components/Vote';
import VoteNew from '../../components/VoteNew';
import RevisionVote from '../../components/RevisionVote';
import RevisionVoteNew from '../../components/RevisionVoteNew';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/atom';
import { postRequest } from '../../utils/ajax';

const { Column } = Table;

interface FusionSongProps extends SongProps {
  type?: string;
  changeTo?: number;
}

interface VoteTableProps {
  table: string;
}

const TableComponent: React.FC<VoteTableProps> = ({ table }) => {
  const [dataSource, setDataSource] = useState<FusionSongProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);

  const user = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/vote/songs', {
        table,
      });

      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
      } else {
        setCount(result.count);
        setCount2(result.count2);
        setDataSource([...result.source]);
      }
      setLoading(false);
    };
    setDataSource([]);
    setLoading(true);
    onTableChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  return (
    <div>
      <div className="normal-container text-center">
        <h2>
          {formatMessage({ id: 'votePage.totalVoteCharts' })}
          {count}
          {count2
            ? ` + ${count2}(${formatMessage({
                id: 'votePage.type.resurrection',
              })})`
            : null}
        </h2>
      </div>
      <Table<FusionSongProps>
        bordered
        scroll={{ x: 720 }}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        rowKey="_id"
        size="small"
        onHeaderRow={() => {
          return {
            style: { backgroundColor: 'white' },
          };
        }}
      >
        <Column<FusionSongProps>
          ellipsis
          key="title"
          title={formatMessage({ id: 'votePage.th.0' })}
          dataIndex="title"
          render={(plaintext, record) => {
            return (
              <span>
                {record.type ? (
                  <Popover content={formatMessage({ id: 'votePage.onRevote' })}>
                    <Tag color="blue">{formatMessage({ id: 'votePage.type.resurrection' })}</Tag>
                  </Popover>
                ) : null}
                <a href={irUrl(record.md5, 'lr2')}>
                  {table === 'dpst' ? `st${record.level} ${record.title}` : ''}
                  {table === 'dp' ? `sl${record.level} ${record.title}` : ''}
                  {table !== 'dpst' && table !== 'dp' ? `${table}${record.level} ${record.title}` : ''}
                </a>
              </span>
            );
          }}
          width={300}
        />
        <Column<FusionSongProps>
          ellipsis
          key="artist"
          title={formatMessage({ id: 'votePage.th.1' })}
          dataIndex="artist"
          render={(plaintext, record) => (
            <span>
              <a href={record.url}>{record.artist}</a>
            </span>
          )}
          width={180}
        />
        <Column<FusionSongProps>
          key="chart"
          title={formatMessage({ id: 'votePage.th.2' })}
          dataIndex="chart"
          align="center"
          render={(plaintext, record) =>
            record.url_diff ? (
              <span>
                <a href={record.url_diff}>
                  <DownloadOutlined style={{ fontSize: '1.2em' }} />
                </a>
              </span>
            ) : null
          }
          width={60}
        />
        <Column<FusionSongProps>
          key="vote"
          title={formatMessage({ id: 'votePage.th.3' })}
          dataIndex="vote"
          align="center"
          render={(plaintext, record) => {
            let status: string = 'success';
            if (!user.userid) status = 'notLogin';
            else if (
              (table === 'st' && user.restriction < record.level) ||
              (table === 'sl' && user.restriction2 < record.level && user.restriction < 0) ||
              (table === 'dpst' && user.restriction3 < 0) ||
              (table === 'dp' && user.restriction4 < record.level && user.restriction3 < 0)
            )
              status = 'restricted';
            else if (!record.type && record.voteVersion === 1) {
              const voted = record.voters.find((i) => i.voter.nickname === user.nickname);
              if (voted) {
                status = voted.voterResult;
              } else if (dayjs(record.publishedTime).add(30, 'day').isBefore(dayjs())) {
                status = 'timeExceed';
              }
            } else if (!record.type && record.voteVersion === 2) {
              const voted = record.voters.find((i) => i.voter.nickname === user.nickname);
              const voted2 = record.voters2.find((i) => i.voter.nickname === user.nickname);
              if (voted) {
                status = voted.voterResult;
              } else if (voted2) {
                status = voted2.voterResult;
              } else if (dayjs(record.publishedTime).add(30, 'day').isBefore(dayjs())) {
                status = 'timeExceed';
              }
            }
            if (record.type && record.voteVersion === 1) {
              const voted = record.voters.find((i) => i.voter.nickname === user.nickname);
              if (voted) {
                status = voted.voterResult;
              } else if (dayjs(record.publishedTime).add(30, 'day').isBefore(dayjs())) {
                status = 'timeExceed';
              }
              return (
                <RevisionVote
                  originalLevel={record.level}
                  status={status}
                  submission={record.entryNo}
                  table={table}
                  type={record.type}
                />
              );
            } else if (record.type && record.voteVersion === 2) {
              const voted = record.voters.find((i) => i.voter.nickname === user.nickname);
              const voted2 = record.voters2.find((i) => i.voter.nickname === user.nickname);
              if (voted) {
                status = voted.voterResult;
              } else if (voted2) {
                status = voted2.voterResult;
              } else if (dayjs(record.publishedTime).add(30, 'day').isBefore(dayjs())) {
                status = 'timeExceed';
              }
              return (
                <RevisionVoteNew
                  originalLevel={record.level}
                  status={status}
                  submission={record.entryNo}
                  table={table}
                  type={record.type}
                  changeTo={record.changeTo}
                />
              );
            }
            if (record.voteVersion === 1) {
              return <Vote status={status} submission={record.entryNo} table={table} />;
            }
            return <VoteNew status={status} submission={record.entryNo} table={table} />;
          }}
          width={60}
        />
        <Column<FusionSongProps>
          key="submission"
          title={formatMessage({ id: 'votePage.th.4' })}
          dataIndex="submission"
          align="center"
          render={(plaintext, record) => (
            <Link
              to={
                record.type ? `../u/${table}/${record.entryNo}` : `../s/${table}/${record.entryNo}`
              }
            >
              <Button size="small">
                <EnterOutlined />
              </Button>
            </Link>
          )}
          width={60}
        />
        <Column<FusionSongProps>
          key="proposer"
          title={formatMessage({ id: 'votePage.th.5' })}
          dataIndex="proposer"
          render={(plaintext, record) => (
            <Link to={`/user/${table}/${record.proposer.userlr2id || record.proposer.minirid}`}>
              {record.proposer.nickname}
            </Link>
          )}
          width={100}
        />
        {table !== 'fr' ? (
          <Column<FusionSongProps>
            key="zureVerification"
            title={formatMessage({ id: 'votePage.th.6' })}
            dataIndex="zureVerification"
            align="center"
            render={(plaintext, record) => {
              if (!record.url_diff) {
                return (
                  <Popover
                    content={
                      <span>
                        {formatMessage({
                          id: 'votePage.isNeutralZureVerification',
                        })}
                      </span>
                    }
                  >
                    <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: '20px' }} />
                  </Popover>
                );
              }
              if (record.zureVerification) {
                return (
                  <Popover
                    content={
                      <span>
                        {formatMessage({ id: 'votePage.isZureVerification' })}
                        <a href={irUrl(record.originMd5, 'lr2')}>Click</a>
                      </span>
                    }
                  >
                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} />
                  </Popover>
                );
              }
              return (
                <Popover
                  content={<span>{formatMessage({ id: 'votePage.isNotZureVerification' })}</span>}
                >
                  <CloseCircleTwoTone twoToneColor="#cd5c5c" style={{ fontSize: '20px' }} />
                </Popover>
              );
            }}
            width={100}
          />
        ) : null}
        <Column<FusionSongProps>
          key="comments"
          title="COM"
          dataIndex="comments"
          render={(plaintext, record) =>
            !record.type ? (
              <Link to={`../s/${table}/${record.entryNo}`}>{record.comments.length}</Link>
            ) : null
          }
          width={60}
        />
        <Column<FusionSongProps>
          key="votes"
          title={formatMessage({ id: 'votePage.th.7' })}
          dataIndex="votes"
          render={(plaintext, record) => (
            <span>{record.voteYes + record.voteNeutral + record.voteNo}</span>
          )}
          width={80}
        />
        {user.userid === 'luxury__a' ? (
          <Column<FusionSongProps>
            key="status"
            title={formatMessage({ id: 'votePage.th.8' })}
            dataIndex="status"
            render={(plaintext, record) => {
              const percent = (
                ((record.voteYes * 2 + record.voteNeutral) * 100) /
                ((record.voteYes + record.voteNeutral + record.voteNo) * 2)
              ).toFixed(1);
              return (
                <span>{`${record.voteYes}/${record.voteNeutral}/${record.voteNo}(${percent})`}</span>
              );
            }}
            sorter={(a, b) => a.votePercent - b.votePercent}
            width={120}
          />
        ) : null}
        <Column<FusionSongProps>
          key="deadline"
          title={formatMessage({ id: 'votePage.th.9' })}
          dataIndex="deadline"
          render={(plaintext, record) => {
            return (
              <span>
                {dayjs(record.publishedTime).add(30, 'day').format('YYYY/MM/DD ')}
                {(() => {
                  if (dayjs(record.publishedTime).add(30, 'day').isBefore(dayjs())) {
                    return (
                      <Popover content={formatMessage({ id: 'votePage.deadline' })}>
                        <ExclamationCircleTwoTone twoToneColor="#ff0000" />
                      </Popover>
                    );
                  }
                  if (dayjs(record.publishedTime).add(23, 'day').isBefore(dayjs())) {
                    return (
                      <Popover
                        content={formatMessage({
                          id: 'votePage.deadlineWarning',
                        })}
                      >
                        <ExclamationCircleTwoTone twoToneColor="#fce205" />
                      </Popover>
                    );
                  }
                  return null;
                })()}
              </span>
            );
          }}
          sorter={(a, b) => dayjs(a.publishedTime).unix() - dayjs(b.publishedTime).unix()}
          width={120}
        />
        <Column<FusionSongProps>
          ellipsis
          key="comment"
          title={formatMessage({ id: 'votePage.th.10' })}
          dataIndex="comment"
          render={(plaintext, record) => (
            <Popover content={record.comment}>
              <span>{record.comment}</span>
            </Popover>
          )}
          width={180}
        />
      </Table>
    </div>
  );
};

export default TableComponent;
